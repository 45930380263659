import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import ACthree from '../sections/meso/mesotwo.js';
import ACt from '../sections/meso/mesocontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Acco from "../sections/meso/mesofaq.js"; 
import Testimonial from '../sections/index/indexreviews.js';   
import Award from "../components/NewToxane.js";
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/mesotherapy-hero.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import Trend from '../components/trends.js';
import PopupForm from "../components/PopupForm/PopupForm"

const MesotPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
  <>
 <Head title="Mesotherapy in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Mesotherapy </span> <span style="color: #00aec7;">Facial</span>'
    secondText="Instand glow up"
    videoAlt="Mesotherapy"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="3 treatments"
  bestalt="best Mesotherapy"
  resultstext="Up to 3 months"
  resultsalt="Mesotherapy results"
  costdata="£180"
  costalt="Mesotherapy Cost"
  timedata="20 Minutes"
  timealt="Mesotherapy Duration Time"
  workdata="Immediately"
  workalt="Mesotherapy downtime work"
  paindata="None / Minimal"
  painalt="Mesotherapy Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
  <ACt
    setIsModalOpen={setIsModalOpen}
  />
  <Award />
      <Testimonial />
      
      <ACthree />
      <Acco
        setIsModalOpen={setIsModalOpen}
      />
     
 
  <Clinic /> 
   <Saveface />
   <Trend />
     </PageWrapper>
    
  </>
)}
export default MesotPage
